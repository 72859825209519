<template>
  <div>
    <main class="container">
      <link-component :pageFrom="'linksuteis'"></link-component>

      <div class="row g-5">
        <div class="col-md-8">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">
                <a href="/artigos/">Observatório Social</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ atual.titulo }}
              </li>
            </ol>
          </nav>

          <article class="blog-post mt-4">
            <h2 class="blog-post-title">{{ atual.titulo }}</h2>
            <p class="blog-post-meta" v-if="moment">
              {{ moment(atual.created_at).format("DD MMMM YYYY") }}
            </p>

            <p>
              <span v-html="atual.subtitulo"></span>
            </p>

            <hr />
            <nav v-if="atual.pdf_path" class="blog-pagination mb-2" aria-label="Pagination">
              <a class="btn btn-primary btn-lg" :href="urlPdf" target="blank">Visualizar Pesquisa/Estudo
                <i class="bi bi-file-earmark-pdf-fill"></i></a>
            </nav>
            <p>
              <span v-html="atual.conteudo"></span>
            </p>

            <EnqueteComponent :enqueteart="atual" :key="atual.id" />

            <img v-if="atual.image_path" :src="urlImg" class="img-fluid rounded w-100" :alt="atual.titulo"
              :title="atual.titulo" />
            <img v-else src="../../../assets/images/semimagem.jpg" class="img-fluid rounded" :alt="atual.titulo"
              :title="atual.titulo" />

            <div class="row col-12 text-right mx-auto mt-4 p-2 border-left-primary">
              <div class="text-muted" style="font-size: 1.3rem">
                Compartilhar:
                <a :href="'https://www.facebook.com/sharer/sharer.php?u=https://institutoideias-sh.com.br/ler/artigo/' +
                  atual.id +
                  '&amp;src=sdkpreparse'
                  " class="link-info fb-xfbml-parse-ignore" target="_blank" data-layout="button_count"><i
                    class="bi bi-facebook"></i>
                </a>
                <a :href="'tg://msg?text=http://institutoideias-sh.com.br/ler/artigo/' +
                  atual.id
                  " target="_blank" class="link-info"><i class="bi bi-telegram"></i></a>
                <a :href="'whatsapp://send?text=http://institutoideias-sh.com.br/ler/artigo/' +
                  atual.id
                  " target="_blank" class="link-info"><i class="bi bi-whatsapp"></i></a>
              </div>
            </div>

            <div class="p-3 bg-light rounded text-muted mt-4">
              <i class="bi bi-info-circle"></i> Ficou alguma dúvida sobre o
              estudo, artigo ou pesquisa?
              <a href="#" class="link-info" data-bs-toggle="modal" data-bs-target="#contato">Fale Conosco</a>!
            </div>
          </article>

          <div class="row mb-2 bg-light border-top border-bottom">
            <h3 class="pb-4 mb-1 py-4 fst-italic">Leia Também.</h3>
            <div class="col-md-4" v-for="artigosLeiaTambem in leiaTambemList" :key="artigosLeiaTambem.id">
              <div class="
                  row
                  g-0
                  border
                  rounded
                  overflow-hidden
                  flex-md-row
                  mb-4
                  shadow-sm
                  position-relative
                ">
                <div class="col p-4 d-flex flex-column position-static">
                  <h3 class="mb-0">{{ artigosLeiaTambem.titulo }}</h3>
                  <div class="mb-1 text-muted" v-if="moment">
                    {{
                      moment(artigosLeiaTambem.created_at).format(
                        "DD MMMM YYYY"
                      )
                    }}
                  </div>
                  <p class="card-text mb-auto">
                    <span v-html="artigosLeiaTambem.subtitulo"></span>
                  </p>
                  <a class="btn btn-outline-primary btn-sm mt-3" :href="'/ler/artigo/' + artigosLeiaTambem.id">Saiba
                    Mais...</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="position-sticky" style="top: 2rem">
            <div class="p-4 mb-3 bg-light rounded">
              <h4 class="fst-italic text-center">Acompanhe Nossas Redes.</h4>
              <div class="row">
                <div class="col text-center" style="font-size: 1.1em">
                  <social-component></social-component>
                </div>
              </div>
            </div>

            <div class="p-4">
              <estudos-lateral-component></estudos-lateral-component>
            </div>

            <div class="p-4">
              <notas-lateral-component></notas-lateral-component>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 mb-4">
        <div id="fb-root"></div>
        <apoio-component></apoio-component>
      </div>
    </main>
  </div>
</template>

<script>
import { URL_BASE } from "../../../services/Commons";
import ArtigoService from "../../../services/artigo.service";
// import EnqueteService from "../../../services/enquete.service";
import apoioComponent from "../../../components/apoioComponent.vue";
import linkComponent from "../../../components/linkComponent.vue";
import socialComponent from "../../../components/socialComponent.vue";
import estudosLateralComponent from "../../../components/estudosLateralComponent.vue";
import notasLateralComponent from "../../../components/notasLateralComponent.vue";
import EnqueteComponent from "../../../components/enqueteComponent.vue";
import moment from "moment";
import { event } from "vue-gtag";
import seo from "../../../mixins/seo.js";

export default {
  name: "Artigo",
  props: ["id"],
  mixins: [seo],
  data: () => ({
    leiaTambemList: [],
    enqueteList: [],
    atual: {},
    urlBase: "",
    urlPdf: "",
    urlImg: "",
    moment: null,
  }),
  components: {
    apoioComponent,
    linkComponent,
    socialComponent,
    estudosLateralComponent,
    notasLateralComponent,
    EnqueteComponent,
  },
  mounted() {
    event("entrou_ler_artigo", { method: "Google" });
    this.moment = moment;
    this.moment.locale("pt-br");

    this.urlBase = URL_BASE;
    ArtigoService.getOne(this.id).then((result) => {
      if (result.status < 400) {
        this.atual = result.data;
        this.urlPdf = URL_BASE + `artigodowpdf/${this.atual.id}/`;
        this.urlImg = URL_BASE + `artigodowimagem/${this.atual.id}/`;

        this.setMeta("og:image", this.urlImg);
        this.setMeta("image", this.urlImg);
        this.setMeta("og:title", this.atual.titulo);
        this.setTitle(this.atual.titulo);
      }
    });

    ArtigoService.getPaginacao(3).then((result) => {
      if (result.status < 400) {
        this.leiaTambemList = result.data;
      }
    });
  }
};
</script>
